import { Button, Flex, Typography } from "@nestoca/ui";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight } from "react-icons/bs";

import { HighlightsCard } from "@components/highlights-card";

import styles from "./rate-card.module.scss";

export const RateCard = ({ ratesLink }: { ratesLink: string }) => {
  const { t } = useTranslation("dashboard");

  return (
    <HighlightsCard className={styles.card}>
      <Flex direction="column">
        <Typography size={3} weight={7}>
          {t("rateCard.title")}
        </Typography>
        <Typography size={0}> {t("rateCard.subTitle")}</Typography>
      </Flex>
      <Button
        as="a"
        size="large"
        variant="secondary"
        rightIcon={<BsBoxArrowUpRight size={20} />}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        href={ratesLink}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        target="blank"
        // the system ui gonna remove the background and color with target blank
        // ¯\_(ツ)_/¯ no idea why and since when, but that's not the behavior we want
        className={styles["card__seerates"]}
      >
        {t("rateCard.link")}
      </Button>
    </HighlightsCard>
  );
};
